// to fix enable disable bs
import React, { useContext, useState, useEffect } from 'react';
import { Table, Form, Button, Container, Tabs, Tab, Modal } from 'react-bootstrap';
import Header from '../Header/Header';
import "./TestDashboard.css";
import { useHistory } from "react-router-dom";
import { titles_map } from "../utils/titles_map";
import axios from "axios";
import { UserContext } from "../../App";

const TestDashboard = () => {

    const history = useHistory();

    const { classes, testsStatus } = useContext(UserContext);
    const [checkedState, setCheckedState] = useState([]);
    const [activeTab, setActiveTab] = useState(0); 

    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    useEffect(() => {
        if (testsStatus) {
            const initialCheckedState = testsStatus.map(classTestStatus => Object.values(classTestStatus.test_status_map));
            setCheckedState(initialCheckedState);
        }
    }, [testsStatus]);

    const handleCheckboxChange = (index, classIndex) => {
        setCheckedState(prevState => prevState.map((value, i) => {
            if (i === classIndex) {
                return value.map((v, j) => j === index ? !v : v);
            }
            return value;
        }));
    };

    const handleSaveChanges = async () => {
        const userSelections = checkedState[activeTab];

        let updatedTestStatus = {};
        for (let i = 0; i < userSelections.length; i++) {
            updatedTestStatus[i + 1] = userSelections[i];
        }

        try {
            const updateTestsStatusRequestBody = {
                "id": testsStatus[activeTab].id,
                "class_src_id": testsStatus[activeTab].class_src_id,
                "test_status_map": updatedTestStatus
            };
            const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/v1/test/updateTestStatus`, updateTestsStatusRequestBody, {
                withCredentials: true
            });
            if (response.status === 201) {
                setShowModal(true);
                setModalMessage('Updated tests visibility');
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    // Create a lookup map from the classes array
    const classesMap = {};
    classes.forEach((classItem) => {
        classesMap[classItem.id] = classItem.title;
    });

    return (
        <Container>
            <Header />

            <div className="buttonContainer">
                <Button variant="secondary" onClick={() => history.push('/')}>Go Back</Button>
            </div>

            {testsStatus && testsStatus.length >= 1 && (
                <Tabs defaultActiveKey="0" id="uncontrolled-tab-example" onSelect={(k) => setActiveTab(Number(k))}>
                    {testsStatus.map((classTestStatus, classIndex) => {
                        // Use the lookup map to find the corresponding title
                        const title = classesMap[classTestStatus.class_src_id];
                        return (
                            <Tab eventKey={classIndex} title={title}>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Chapter Name</th>
                                            <th>Enable tests</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {titles_map.map((title, index) => (
                                            <tr key={index}>
                                                <td>{title.title}</td>
                                                <td>
                                                    <Form.Check
                                                        type="checkbox"
                                                        checked={checkedState[classIndex] && checkedState[classIndex][index]}
                                                        onChange={() => handleCheckboxChange(index, classIndex)}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Tab>
                        );
                    })}
                </Tabs>
            )}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Success</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Ok</Button>
                </Modal.Footer>
            </Modal>

            <div className="buttonContainer">
                <Button variant="primary" onClick={handleSaveChanges}>Save Changes</Button>
            </div>
        </Container>
    );
};

export default TestDashboard;