import React, {createContext, useState, useEffect} from "react";
import './App.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import Home from "./components/Home/Home";
import TestDashboard from "./components/TestDashboard/TestDashboard"
import Login from "./components/Login/Login";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import SpecialRoute from "./components/SpecialRoute/SpecialRoute";
import TestPage from "./components/TestPage/TestPage";
import TestResults from "./components/TestResults/TestResults";
import {checkLoggedInHook} from "./components/Login/LoginManager";
import axios from "axios";
import TestReport from "./components/TestReport/TestReport";
import TestStatistics from "./components/TestStatistics/TestStatistics";

export const UserContext = createContext();

function App() {
    const [loggedInUser, setLoggedInUser] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [classes, setClasses] = useState([])
    const [testsStatus, setTestsStatus] = useState(null);

    useEffect(() => {
        const storedLoggedInUser = sessionStorage.getItem('loggedInUser');
        if (storedLoggedInUser) {
            setLoggedInUser(JSON.parse(storedLoggedInUser));
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        if (window.location.pathname !== "/login") {
            checkLoggedInHook();
        }
    }, []);

    useEffect(() => {
        const getTestsStatus = async () => {
            try {
                const results = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/test/getTestsStatus`, {
                    withCredentials: true
                });
                setTestsStatus(results.data.test_statuses)
            } catch (error) {
                console.error(error);
            }
        }

        const getClasses = async () => {
            try {
                const results = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/class/getClasses`, {
                    withCredentials: true
                });
                setClasses(results.data.classes)
            } catch (error) {
                console.error(error);
            }
        }

        getTestsStatus();
        getClasses();
    }, []);
    return (
        <UserContext.Provider value={{loggedInUser, setLoggedInUser, isLoading, classes, testsStatus}}>
            <Router>
                <Switch>
                    <Route path="/login">
                        <Login/>
                    </Route>
                    <PrivateRoute exact path="/" component={Home}/>
                    <SpecialRoute exact path="/testDashboard" component={TestDashboard}/>
                    <PrivateRoute path="/chapterTest/:number" component={TestPage} />
                    <SpecialRoute path="/testResults/:number" component={TestResults}/>
                    <SpecialRoute path="/testStatistics/:number" component={TestStatistics}/>
                    <SpecialRoute path="/testReport" component={TestReport}/>
                </Switch>
            </Router>
        </UserContext.Provider>
    );
}

export default App;