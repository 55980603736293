import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Tabs, Tab, Table, Button, Spinner, Alert } from 'react-bootstrap';
import { UserContext } from "../../App";
import { test_status_map, titles_map } from "../utils/titles_map";
import Header from "../Header/Header";
import axios from "axios";

const TestStatistics = () => {
    const history = useHistory();
    const { classes } = useContext(UserContext);
    const [activeTab, setActiveTab] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [testQA, setTestQA] = useState();
    const [testStatistics, setTestStatistics] = useState();
    const { number } = useParams();
    const currentTestStatusMap = test_status_map[number];
    const [classEmptyMessageObject, setClassEmptyMessageObject] = useState({});

    useEffect(() => {
        const getTestQuestionsAndAnswer = async () => {
            try {
                const results = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/test/getTestQuestionsAndAnswers`, {
                    headers: {
                        "X-Chapter-URL": window.location.href
                    },
                    withCredentials: true
                });
                setTestQA(results.data.question_and_answers);
                setIsLoading(false);
            } catch (error) {
                console.error(error);
            }
        }
        const getClassesTestStatistics = async () => {
            try {
                const results = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/test/getTestStatistics`, {
                    headers: {
                        "X-Chapter-URL": window.location.href
                    },
                    withCredentials: true
                });

                const newMessages = {};

                results.data.test_statistic.forEach(classAnswers => {
                    if (Object.keys(classAnswers.QuestionStatistics).length === 0) {
                        newMessages[classAnswers.ClassID] = "There are no submissions from this class yet";
                    } 
                });

                setClassEmptyMessageObject(newMessages);
                setTestStatistics(results.data.test_statistic);
            } catch (error) {
                console.error(error);
            }
        }

        getTestQuestionsAndAnswer();
        getClassesTestStatistics();
    }, []);

    if (isLoading) {
        return (
            <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div>
        );
    }
    const getAnswerLetter = (index) => {
        const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
        return letters[index] || '-';
    };

    const maxOptionsLength = Math.max(...currentTestStatusMap.map(question => {
        let key = Object.keys(question)[0];
        return parseInt(question[key].no_answers);
    }));


    return (
        <Container>

            <Header />

            <h2>{titles_map[number - 1].title}</h2>
            <br />
            <h4>Test statistics</h4>
            <br />
            <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
                {classes.map((classItem, classIndex) => (
                    <Tab eventKey={classIndex + 1} title={classItem.title}>
                        <h3>{classItem.title}</h3>

                        {classItem && classEmptyMessageObject && classItem.id in classEmptyMessageObject && <Alert variant="light">{classEmptyMessageObject[classItem.id]}</Alert>}
                        {classItem && !(classItem.id in classEmptyMessageObject) &&
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th></th>
                                        {
                                            testQA.map((qa, i) => (
                                                qa.answers.length === 2 && qa.answers[0].includes("a") ?
                                                    <><th key={i} style={{ textAlign: 'center' }}>{`Q${i + 1}-1`}</th><th key={i} style={{ textAlign: 'center' }}>{`Q${i + 1}-2`}</th></>
                                                    :
                                                    <th key={i} style={{ textAlign: 'center' }}>{`Q${i + 1}`}</th>
                                            ))
                                        }
                                    </tr>
                                    <tr>
                                        <th>Standard</th>
                                        {testQA.map((qa, i) => (

                                            qa.answers.length === 2 && qa.answers[0].includes("a") ?
                                                <><th key={i} style={{ textAlign: 'center' }}>{qa.standard}</th><th key={i} style={{ textAlign: 'center' }}>{qa.standard}</th></>
                                                :
                                                <th key={i} style={{ textAlign: 'center' }}>{qa.standard}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.from({ length: maxOptionsLength }, (_, j) => (
                                        <tr key={j}>
                                            <td style={{ textAlign: 'center' }}>{getAnswerLetter(j)}</td>
                                            {testQA.map((qa, i) => {
                                                if (qa.answers.length === 2 && qa.answers[0].includes("a")) {

                                                    const stats = testStatistics && testStatistics.find(stats => stats.ClassID === classItem.id);
                                                    const questionStats = stats ? stats.QuestionStatistics[qa.question_index] : {};


                                                    const part_one_answers = Object.keys(questionStats).filter(question => question.endsWith("a"));
                                                    const part_two_answers = Object.keys(questionStats).filter(question => question.endsWith("b"));
                                                    const first_question_index = `${j + 1}a`
                                                    const second_question_index = `${j + 1}b`
                                                    const first_answer_matched = part_one_answers.indexOf(first_question_index);
                                                    const second_answer_matched = part_two_answers.indexOf(second_question_index);

                                                    const questionInfo = currentTestStatusMap[qa.question_index - 1];
                                                    const accessIndex = (qa.question_index).toString()

                                                    if (j + 1 > parseInt(questionInfo[accessIndex].no_answers)) {

                                                        return (
                                                            <><td key={i}
                                                                style={{ backgroundColor: qa.answers.includes(first_question_index) ? 'green' : 'white', textAlign: 'center' }}>
                                                                {"-"}
                                                            </td><td key={i}
                                                                style={{ backgroundColor: qa.answers.includes(second_question_index) ? 'green' : 'white', textAlign: 'center' }}>
                                                                    {"-"}
                                                                </td></>
                                                        );

                                                    } else {
                                                        return (
                                                            <><td key={i}
                                                                style={{ backgroundColor: qa.answers.includes(first_question_index) ? 'green' : 'white', textAlign: 'center' }}>
                                                                {first_answer_matched !== -1 ? questionStats[first_question_index] : "0%"}
                                                            </td><td key={i}
                                                                style={{ backgroundColor: qa.answers.includes(second_question_index) ? 'green' : 'white', textAlign: 'center' }}>
                                                                    {second_answer_matched !== -1 ? questionStats[second_question_index] : "0%"}
                                                                </td></>
                                                        );
                                                    }
                                                } else {

                                                    const stats = testStatistics && testStatistics.find(stats => stats.ClassID === classItem.id);
                                                    const questionStats = stats ? stats.QuestionStatistics[qa.question_index] : {};
                                                    const percentage = questionStats[j + 1] ? questionStats[j + 1] : "0%";

                                                    const questionInfo = currentTestStatusMap[qa.question_index - 1];
                                                    const accessIndex = (qa.question_index).toString()

                                                    if (j + 1 > parseInt(questionInfo[accessIndex].no_answers)) {
                                                        return (
                                                            <td key={i}
                                                                style={{ backgroundColor: qa.answers.includes(parseInt(j + 1).toString()) ? 'green' : 'white', textAlign: 'center' }}>
                                                                {"-"}
                                                            </td>
                                                        );
                                                    } else {
                                                        return (
                                                            <td key={i}
                                                                style={{ backgroundColor: qa.answers.includes(parseInt(j + 1).toString()) ? 'green' : 'white', textAlign: 'center' }}>
                                                                {percentage}
                                                            </td>
                                                        );
                                                    }
                                                }
                                            })}
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        }
                    </Tab>
                ))}
            </Tabs>
            <br />
            <div className="buttonContainer">
                <Button variant="secondary" onClick={() => history.goBack()}>Go Back</Button>
            </div>
        </Container >
    );
}

export default TestStatistics;
