import axios from 'axios';
import {decode} from "jsonwebtoken";

export const signInWithEmailAndPassword = (email, password) => {
    return axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/sign/in`, {
        email: email,
        password: password
    }, {
        withCredentials: true
    }).then((response) => {
        return response;
    })
        .catch(error => console.log(error));
}

export async function logout() {
    sessionStorage.removeItem('loggedInUser');
    document.cookie = `refreshSession=true; path=/; domain=${process.env.REACT_APP_COOKIE_DOMAIN}`;
    try {
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/sign/out`, {
            withCredentials: true
        });
    } catch (error) {
        console.error(error);
        window.location.href = `/login`;
    }
    document.location = '/login';

}

export function validateSpecialAction() {
    return axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/validateSpecialAction`, {withCredentials: true})
        .then((response) => {
            return response;
        })
        .catch(error => console.log(error));
}

async function checkLoggedIn() {

    try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/user/isLoggedIn`, {
            withCredentials: true
        });

        const token = response.data.token
        const decodedToken = decode(token);
        const userRole = decodedToken.role;

        const updatedUser = {
            isSignedIn: true,
            role: userRole,
        };

        sessionStorage.setItem('loggedInUser', JSON.stringify(updatedUser));
        if (window.location.pathname === "/login") {
            console.log("aoci")
            window.location.reload();
        }

    } catch (error) {
        console.error(error);
        logout();
        window.location.href = `/login`;
    }

}

const renewToken = async () => {

    try {
        await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/token/renew`, {
            withCredentials: true
        });
    } catch (error) {
        console.error(error);
        window.location.href = `/login`;
    }
};

export async function checkLoggedInHook() {
    const timer = setInterval(() => {
        checkLoggedIn();
        renewToken();
    }, 10 * 60 * 1000); // 10 minutes in milliseconds

    checkLoggedIn();
    renewToken();

    return () => {
        clearInterval(timer);
    };

}