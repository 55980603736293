import React, {useContext, useEffect, useRef, useState} from 'react';
import {Alert, Button, Container, Form, Modal} from 'react-bootstrap';
import Header from '../Header/Header';
import "./TestPage.css";
import {useHistory, useParams} from "react-router-dom";

import {UserContext} from "../../App";
import {test_status_map, titles_map} from "../utils/titles_map";
import axios from "axios";

const TestPage = () => {
    const history = useHistory();
    const {number} = useParams();
    const index = parseInt(number)
    const [errorMessage, setErrorMessage] = useState(null);
    const {testsStatus} = useContext(UserContext);
    const currentTestStatusMap = test_status_map[index];
    const [successMessage, setSuccessMessage] = useState(null); // Add a new state for the success message

    const [htmlContent, setHtmlContent] = useState('');
    const [currentIndex, setCurrentIndex] = useState(1);
    const totalQuestions = titles_map[index - 1]?.no_tests || 0;
    const radioButtonsRef = useRef(null);
    const checkboxesRef = useRef(null);
    //initializing the value with 4 bcs thats usuually how many questions t\here are /test, and all first questions have 4 options(checked)
    const [noAnswers, setNoAnswers] = useState(4);
    const [questionType, setQuestionType] = useState("sq");
    const [userChoices, setUserChoices] = useState({});
    const [adjustmentTreshold, setAdjustmentTreshold] = useState(0);
    const [isPrevButtonDisabled, setIsPrevButtonDisabled] = useState(false);
    const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);
    const [showModal, setShowModal] = useState(false); // Add a new state for controlling the modal

    const [isMultipart, setIsMultipart] = useState(false);


    const [isOptionSelected, setIsOptionSelected] = useState(false);
    const questionFiles = Array.from({length: totalQuestions}, (_, i) => `/test-partials/t${index}/q${i + 1}.html`);

    useEffect(() => {

        try {
            axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/test/getUserTestPermission`, {
                headers: {
                    "X-Chapter-URL": window.location.href
                },
                withCredentials: true
            }).then(permission => {
                    if (!permission.data.allowed_to_take_test) {
                        setErrorMessage("You have already submitted your answers for this test");
                    }
                }
            );
        } catch (error) {
            console.error(error);
        }
    }, []);

    useEffect(() => {
        fetch(questionFiles[currentIndex - 1])
            .then(response => response.text())
            .then(data => {
                setHtmlContent(data);
            });
    }, [currentIndex, questionFiles]);

    useEffect(() => {
        const iframe = document.querySelector('.responsive-iframe');

        const adjustIframeSize = () => {

            if (iframe && iframe.contentWindow && iframe.contentWindow.document.getElementById('pf1')) {
                const contentDiv = iframe.contentWindow.document.getElementById('pf1');
                const contentHeight = contentDiv.scrollHeight;
                const contentWidth = contentDiv.scrollWidth;

                const extraEdge = 20;
                iframe.style.height = 2 * extraEdge + contentHeight + 'px';
                iframe.style.width = extraEdge + contentWidth + 'px';
            }
        };

        if (iframe) {
            // Add the event listener to the iframe instead of the window

            iframe.addEventListener('load', adjustIframeSize);
        }

        // Cleanup: remove the event listener when the component unmounts
        return () => {
            if (iframe) {
                iframe.removeEventListener('load', adjustIframeSize);
            }
        };
    }, []);


    useEffect(() => {
        const iframe = document.querySelector('.responsive-iframe');
        if (iframe) {
            iframe.onload = function () {
                const doc = iframe.contentWindow.document;
                const allRadioButtons = doc.querySelectorAll('input[type="radio"]');
                radioButtonsRef.current = Array.from(allRadioButtons).filter(button => button.offsetParent !== null);

                const allCheckboxes = doc.querySelectorAll('input[type="checkbox"]');
                checkboxesRef.current = Array.from(allCheckboxes).filter(button => button.offsetParent !== null);

                // Check the radio buttons for the current question
                const currentQuestionChoices = userChoices[currentIndex];
                if (currentQuestionChoices) {
                    if (questionType === "mcq") {
                        currentQuestionChoices.unmoduloedChoices.forEach(choiceIndex => {
                            checkboxesRef.current[choiceIndex].checked = true;
                        });
                        setIsOptionSelected(true);
                    } else if (questionType === "sq") {
                        currentQuestionChoices.unmoduloedChoices.forEach(choiceIndex => {
                            radioButtonsRef.current[choiceIndex].checked = true;
                        });
                        setIsOptionSelected(true);
                    } else {
                        setIsOptionSelected(false);
                    }
                }

                let user_multi_answer_modulo_array = [];
                let user_multi_answer_unmoduloed_array = [];

                if ((isMultipart || questionType === "mcq") && userChoices[currentIndex]) {
                    user_multi_answer_modulo_array = userChoices[currentIndex].moduloedChoices;
                    user_multi_answer_unmoduloed_array = userChoices[currentIndex].unmoduloedChoices
                }

                doc.addEventListener('change', (event) => {

                    const currentQuestionChoices = userChoices[currentIndex] || {
                        moduloedChoices: [],
                        unmoduloedChoices: []
                    };

                    if (event.target.type === 'radio' && event.target.checked) {

                        const radioButtonIndex = Array.from(radioButtonsRef.current).indexOf(event.target);
                        let modIndex;
                        if (adjustmentTreshold === 0) {
                            modIndex = radioButtonIndex % noAnswers;
                        } else {
                            modIndex = radioButtonIndex % noAnswers + adjustmentTreshold;
                            if (modIndex === -1) {
                                modIndex = noAnswers - 1;
                            }
                        }

                        if (!isMultipart) {
                            setIsOptionSelected(true); // Update the isOptionSelected state here

                            // Update the choices for the question
                            if (!currentQuestionChoices.moduloedChoices.includes(parseInt(modIndex) + 1)) {
                                currentQuestionChoices.moduloedChoices = [];
                                currentQuestionChoices.moduloedChoices.push((parseInt(modIndex) + 1).toString());
                            }
                            if (!currentQuestionChoices.unmoduloedChoices.includes(radioButtonIndex)) {
                                currentQuestionChoices.unmoduloedChoices = [];
                                currentQuestionChoices.unmoduloedChoices.push(radioButtonIndex);
                            }
                        }

                        if (isMultipart) {
                            const currentChoice = radioButtonsRef.current[radioButtonIndex]
                            if (currentChoice.name === "q1") {
                                const oldElemIndex = user_multi_answer_modulo_array.findIndex(item => item.endsWith("a"));
                                if (oldElemIndex !== -1) {
                                    user_multi_answer_modulo_array.splice(oldElemIndex, 1);
                                    user_multi_answer_unmoduloed_array.splice(oldElemIndex, 1);
                                }
                                user_multi_answer_modulo_array.push(`${modIndex + 1}a`);
                                user_multi_answer_unmoduloed_array.push(radioButtonIndex);
                            } else {
                                const oldElemIndex = user_multi_answer_modulo_array.findIndex(item => item.endsWith("b"));
                                if (oldElemIndex !== -1) {
                                    user_multi_answer_modulo_array.splice(oldElemIndex, 1);
                                    user_multi_answer_unmoduloed_array.splice(oldElemIndex, 1);
                                }
                                user_multi_answer_modulo_array.push(`${modIndex + 1}b`);
                                user_multi_answer_unmoduloed_array.push(radioButtonIndex);

                            }
                            if (user_multi_answer_modulo_array.length === 2) {
                                setIsOptionSelected(true); // Update the isOptionSelected state here
                            }

                            if (!currentQuestionChoices.moduloedChoices.includes(parseInt(modIndex) + 1)) {
                                currentQuestionChoices.moduloedChoices = user_multi_answer_modulo_array;
                            }
                            if (!currentQuestionChoices.unmoduloedChoices.includes(radioButtonIndex)) {
                                currentQuestionChoices.unmoduloedChoices = user_multi_answer_unmoduloed_array;
                            }

                        }

                        setUserChoices({
                            ...userChoices,
                            [currentIndex]: currentQuestionChoices
                        });
                    }

                    //find a stupid condition for this stupid bug
                    if (event.target.type === 'checkbox') {
                        const checkboxIndex = Array.from(checkboxesRef.current).indexOf(event.target);
                        let modIndex;
                        if (adjustmentTreshold === 0) {
                            modIndex = checkboxIndex % noAnswers;
                        } else {
                            //speaking of STUPID
                            modIndex = checkboxIndex % noAnswers + adjustmentTreshold;
                            if (parseInt(noAnswers) === 6) {
                                if (adjustmentTreshold === -2) {
                                    if (modIndex < 0 && modIndex !== -1) {
                                        modIndex = Math.abs(modIndex)
                                    }
                                    if (modIndex === 2) {
                                        modIndex = 4
                                    }
                                    if (modIndex === 5) {
                                        modIndex = 3
                                    }
                                    if (modIndex === -1) {
                                        modIndex = noAnswers - 1;
                                    }
                                }

                                if (adjustmentTreshold === -4) {
                                    if (modIndex === -4) {
                                        modIndex = 2
                                    }
                                    if (modIndex === -3) {
                                        modIndex = 3
                                    }
                                    if (modIndex === -2) {
                                        modIndex = 4
                                    }
                                    if (modIndex === -1) {
                                        modIndex = 5
                                    }
                                }
                            }

                            if (parseInt(noAnswers) === 7) {
                                if (parseInt(modIndex) === -2) {
                                    modIndex = 5;
                                } else if (parseInt(modIndex) === -1) {
                                    modIndex = 6;
                                } else if (parseInt(modIndex) === 6) {
                                    modIndex = 4;
                                }
                            }
                        }

                        // If checkbox is checked
                        if (event.target.checked) {

                            // Update the choices for the question
                            console.log(checkboxIndex)
                            user_multi_answer_modulo_array.push((parseInt(modIndex) + 1).toString());
                            user_multi_answer_unmoduloed_array.push(checkboxIndex);
                        } else if (!event.target.checked) {
                            const modIndexToRemove = user_multi_answer_modulo_array.indexOf(parseInt(modIndex) + 1);
                            const unmodIndexToRemove = user_multi_answer_unmoduloed_array.indexOf(checkboxIndex);

                            if (modIndexToRemove > -1) {
                                user_multi_answer_modulo_array.splice(modIndexToRemove, 1);
                            }
                            if (unmodIndexToRemove > -1) {
                                user_multi_answer_unmoduloed_array.splice(unmodIndexToRemove, 1);
                            }

                            if (user_multi_answer_modulo_array.length < 2) {
                                setIsOptionSelected(false);
                            }
                        }
                        if (user_multi_answer_modulo_array.length === 2) {
                            setIsOptionSelected(true);
                        }

                        if (!currentQuestionChoices.moduloedChoices.includes(parseInt(modIndex) + 1)) {
                            currentQuestionChoices.moduloedChoices = user_multi_answer_modulo_array;
                        }
                        if (!currentQuestionChoices.unmoduloedChoices.includes(checkboxIndex)) {
                            currentQuestionChoices.unmoduloedChoices = user_multi_answer_unmoduloed_array;
                        }

                        setUserChoices({
                            ...userChoices,
                            [currentIndex]: currentQuestionChoices
                        });
                    }
                });

            };
        }
    }, [adjustmentTreshold, currentIndex, htmlContent, isMultipart, noAnswers, questionType, userChoices]);


    useEffect(() => {
        if (!testsStatus) {
            return;
        }

        if (isNaN(index) || index < 1 || index > 19) {
            setErrorMessage("Invalid test index.");
            return;
        }

        //extracting the first element. only teacher might have more than one.
        if (!testsStatus[0].test_status_map[index]) {
            setErrorMessage("The test has not been enabled yet.");
        }

        function extractQuestionParams() {
            const currentQuestionData = Object.values(currentTestStatusMap[currentIndex - 1])[0];
            const {no_answers, type, adjustment_threshold} = currentQuestionData;

            // Save the no_answers value in the state to make it global throughout the component
            setNoAnswers(no_answers);
            setQuestionType(type);

            if (adjustment_threshold) {
                setAdjustmentTreshold(adjustment_threshold);
            } else {
                setAdjustmentTreshold(0);
            }

            if (currentQuestionData.multipart) {
                setIsMultipart(true);
            } else {
                setIsMultipart(false);
            }
        }

        extractQuestionParams();

    }, [testsStatus, index, currentTestStatusMap, currentIndex]);


    const handleNextClick = () => {
        if (!isOptionSelected) {
            setErrorMessage("Please select an option before proceeding.");
            return;
        }
        setIsNextButtonDisabled(true);
        setTimeout(() => {
            setIsNextButtonDisabled(false);
        }, 500);
        setCurrentIndex(currentIndex + 1);
        if (currentIndex < totalQuestions) {
            setIsOptionSelected(false);
        } else {
            handleSubmit();
        }
    };

    const handlePreviousClick = () => {

        if (isPrevButtonDisabled) {
            return;
        }

        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }

        setIsPrevButtonDisabled(true);

        setTimeout(() => {
            setIsPrevButtonDisabled(false);
        }, 500);
    }

    const handleSubmit = () => {
        setShowModal(true);
    };

    const handleClose = () => setShowModal(false);
    const handleConfirmSubmit = async () => {
        let requestBody = {};
        for (let key in userChoices) {
            requestBody[key] = userChoices[key].moduloedChoices;
        }

        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/test/submitAnswers`, {
                    requestBody
                }, {
                    headers: {
                        "X-Chapter-URL": window.location.href
                    },
                    withCredentials: true
                }
            );
            setShowModal(false);
            setSuccessMessage("Your answer has been recorded."); // Set the success message
            setTimeout(() => {
                window.location.href = `/`; // Redirect the user after 3 seconds
            }, 1000);
        } catch (error) {
            console.error(error);
            window.location.href = `/login`;
        }

    };

    return (
        <Container>
            <Header/>
            <Form>
                {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                {successMessage && <Alert variant="success">{successMessage}</Alert>} {/* Display the success message */}
                {!errorMessage && !successMessage && (
                    <div className="iframe-container">
                        <iframe
                            className="responsive-iframe"
                            srcDoc={htmlContent}
                            frameBorder="0"
                            scrolling="no" // Disables scrolling
                        />
                    </div>
                )}
                {!errorMessage && !successMessage &&
                    <div className="button-container"
                         style={{display: 'flex', justifyContent: 'space-between', marginBottom: '2%'}}>
                        <div>
                            {currentIndex < totalQuestions &&
                            <Button variant="primary" disabled={!isOptionSelected || isNextButtonDisabled}
                                        onClick={handleNextClick}>Next</Button>}
                            {isOptionSelected && currentIndex === totalQuestions - 1 + 1 &&
                                <Button variant="primary" style={{backgroundColor: 'green'}}
                                        onClick={handleSubmit}>Submit</Button>}
                            {!isOptionSelected && currentIndex === totalQuestions - 1 + 1 &&
                                <Button variant="primary" style={{backgroundColor: 'grey'}}
                                        disabled={!isOptionSelected}
                                        onClick={handleSubmit}>Submit</Button>}
                        </div>
                        {currentIndex > 1 && <Button variant="primary"
                                                     disabled={isPrevButtonDisabled}
                                                     onClick={handlePreviousClick}>Previous</Button>}
                    </div>
                }
            </Form>
            <div className="buttonContainer">
                <Button variant="secondary" onClick={() => history.push('/')}>Go Back</Button>
            </div>

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Submission</Modal.Title>
                </Modal.Header>
                <Modal.Body>Submit your answers?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        No
                    </Button>
                    <Button variant="primary" onClick={handleConfirmSubmit}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default TestPage
