import React, {useContext, useEffect, useState} from 'react';
import {Table, Container, Tabs, Tab, Modal, Button, Alert} from 'react-bootstrap';
import Header from '../Header/Header';
import {Link, useHistory, useParams} from "react-router-dom";
import axios from "axios";
import {UserContext} from "../../App";
import {titles_map} from "../utils/titles_map";

const TestResults = () => {
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [testResults, setTestResults] = useState();
    const {classes} = useContext(UserContext);
    const [testQA, setTestQA] = useState();
    const [activeTab, setActiveTab] = useState(1);
    const [maxAttempts, setMaxAttempts] = useState(0);
    const {number} = useParams();
    const [emptySubmission, setEmptySubmission] = useState(false);


    useEffect(() => {
        const getTestsResults = async () => {
            try {
                const results = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/test/getUsersTestResults`, {
                    headers: {
                        "X-Chapter-URL": window.location.href
                    },
                    withCredentials: true
                });
                if (results.data.test_statuses != null) {
                    setTestResults(results.data.test_statuses)
                } else {
                    setEmptySubmission(true);
                }
            } catch (error) {
                console.error(error);
            }
        }

        const getTestQuestionsAndAnswer = async () => {
            try {
                const results = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/test/getTestQuestionsAndAnswers`, {
                    headers: {
                        "X-Chapter-URL": window.location.href
                    },
                    withCredentials: true
                });
                setTestQA(results.data.question_and_answers)
            } catch (error) {
                console.error(error);
            }
        }

        getTestsResults();
        getTestQuestionsAndAnswer();
    }, []);

    useEffect(() => {
        if (testResults && testQA) {
            // Calculate max attempts
            let max = 0;
            for (let user in testResults) {
                let attempts = Object.keys(testResults[user].user_submission_attempts).length;
                if (attempts > max) {
                    max = attempts;
                }
            }
            setMaxAttempts(max);
        }
    }, [testResults, testQA]);

    const checkAnswer = (userAnswers, questionIndex) => {
        if (testQA) {
            const correctAnswers = testQA.find(qa => qa.question_index === questionIndex)?.answers;
            return JSON.stringify(userAnswers.sort()) === JSON.stringify(correctAnswers?.sort()) ? '✅' : '❌';
        }
        return '';
    }
    const handleOpenModal = (user) => {
        setCurrentUser(user);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleConfirmRetake = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/test/enableUserTestPermission`, {
                    "userID": currentUser.user_src_id,
                    "testIndex": parseInt(number)
                }, {
                    headers: {
                        "X-Chapter-URL": window.location.href
                    },
                    withCredentials: true
                }
            );
        } catch (error) {
            console.error(error);
        }
        setShowModal(false);
    };

    return (
        <Container>
            <Header/>

            <div className="buttonContainer">
                <Button variant="secondary" onClick={() => history.goBack()}>Go Back</Button>
            </div>
            <h2>{titles_map[number - 1].title}</h2>
            <br/>
            <h4>Test results</h4>
            <br/>
            {emptySubmission &&
                <Alert variant="light">There are no user submissions yet. </Alert>


            }
            {!emptySubmission && <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
                {[...Array(maxAttempts)].map((_, i) => (
                    <Tab eventKey={i + 1} title={`Attempt ${i + 1}`}>
                        {classes.map((classItem) => {
                            const studentsInClass = Object.values(testResults).filter(user => user.user_submission_attempts[i + 1] && user.class_src_id === classItem.id);
                            if (studentsInClass.length === 0) {
                                return null;
                            }
                            return (
                                <>
                                    <h3>{classItem.title}</h3>
                                    <Table striped bordered hover>
                                        <thead>
                                        <tr>
                                            <th>Student</th>
                                            {testQA.map((_, i) => (
                                                <th>{`Q${i + 1}`}</th>
                                            ))}
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {studentsInClass.map((user) => (
                                            <tr>
                                                <td style={{textAlign: 'center'}}>
                                                    <Link to={{
                                                        pathname: "/testReport",
                                                        state: {
                                                            userSubmissionAttempts: user.user_submission_attempts,
                                                            testQA: testQA,
                                                            firstName: user.first_name,
                                                            givenName: user.given_name,
                                                        }
                                                    }}>
                                                        {`${user.first_name} ${user.given_name}`}
                                                    </Link>
                                                </td>
                                                {testQA.map((_, index) => (

                                                    <td style={{textAlign: 'center'}}>
                                                        {checkAnswer(user.user_submission_attempts[i + 1]?.[index + 1] || [], index + 1)}</td>
                                                ))}
                                                <td style={{textAlign: 'center'}}><Button
                                                    onClick={() => handleOpenModal(user)}>Retake</Button></td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>
                                    <Modal show={showModal} onHide={handleCloseModal}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Confirm Action</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>This action will allow the student to retake the test.
                                            Continue?</Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleCloseModal}>
                                                No
                                            </Button>
                                            <Button variant="primary" onClick={handleConfirmRetake}>
                                                Yes
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </>
                            );
                        })}
                    </Tab>
                ))}
            </Tabs>
            }
        </Container>
    );


}

export default TestResults;
