export const presList= [  {
    "title": "Chapter 18 Presentation",
    "chapter_index":"Chapter 18",
    "root_name": "presentation18.pdf",
    "start_page": "1",
    "url": `${process.env.REACT_APP_DW_URL}/presentations/presentation18`,
    "length": "32"
},
    {
        "title": "Chapter 19 Presentation",
        "chapter_index":"Chapter 19",
        "root_name": "documents/presentation19.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/presentations/presentation19`,
        "length": "34"
    }
];
