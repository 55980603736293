import React, {useContext} from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import "./Navbar.css"
import { UserContext } from '../../App';
import { logout} from '../Login/LoginManager';


const BurgerMenu = () => {

  const { loggedInUser } = useContext(UserContext);

  return (
      <DropdownButton id="dropdown-basic-button" title="☰" className="custom-dropdown">
      
      {loggedInUser.role === 'tutor' && <Dropdown.Item href="/testDashboard">Test Dashboard</Dropdown.Item>}
      <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
    </DropdownButton>
  );
};

export default BurgerMenu;