export const rcList= [  {
    "title": "Chapter 1: Review Cards",
    "chapter_index":"Chapter 1",
    "root_name": "rc1.pdf",
    "start_page": "1",
    "url":`${process.env.REACT_APP_DW_URL}/rc/reviewcard1`,
    "length": "7"
},
    {
        "title": "Chapter 2: Review Cards",
        "chapter_index":"Chapter 2",
        "root_name": "rc2.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/rc/reviewcard2`,
        "length": "7"
    },
    {
        "title": "Chapter 3: Review Cards",
        "chapter_index":"Chapter 3",
        "root_name": "rc3.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/rc/reviewcard3`,
        "length": "6"
    },
    {
        "title": "Chapter 4: Review Cards",
        "chapter_index":"Chapter 4",
        "root_name": "rc4.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/rc/reviewcard4`,
        "length": "7"
    },
    {
        "title": "Chapter 5: Review Cards",
        "chapter_index":"Chapter 5",
        "root_name": "rc5.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/rc/reviewcard5`,
        "length": "5"
    },

    {
        "title": "Chapter 6: Review Cards",
        "chapter_index":"Chapter 6",
        "root_name": "rc6.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/rc/reviewcard6`,
        "length": "8"
    },
    {
        "title": "Chapter 7: Review Cards",
        "chapter_index":"Chapter 7",
        "root_name": "rc7.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/rc/reviewcard7`,
        "length": "3"
    },
    {
        "title": "Chapter 8: Review Cards",
        "chapter_index":"Chapter 8",
        "root_name": "rc8.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/rc/reviewcard8`,
        "length": "3"
    },
    {
        "title": "Chapter 9: Review Cards",
        "chapter_index":"Chapter 9",
        "root_name": "rc9.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/rc/reviewcard9`,
        "length": "5"
    },
    {
        "title": "Chapter 10: Review Cards",
        "chapter_index":"Chapter 10",
        "root_name": "rc10.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/rc/reviewcard10`,
        "length": "3"
    },
    {
        "title": "Chapter 11: Review Cards",
        "chapter_index":"Chapter 11",
        "root_name": "rc11.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/rc/reviewcard11`,
        "length": "3"
    },
    {
        "title": "Chapter 12: Review Cards",
        "chapter_index":"Chapter 12",
        "root_name": "rc12.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/rc/reviewcard12`,
        "length": "4"
    },
    {
        "title": "Chapter 13: Review Cards",
        "chapter_index":"Chapter 13",
        "root_name": "rc13.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/rc/reviewcard13`,
        "length": "4"
    },
    {
        "title": "Chapter 14: Review Cards",
        "chapter_index":"Chapter 14",
        "root_name": "rc14.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/rc/reviewcard14`,
        "length": "6"
    },
    {
        "title": "Chapter 15: Review Cards",
        "chapter_index":"Chapter 15",
        "root_name": "rc15.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/rc/reviewcard15`,
        "length": "6"
    },
    {
        "title": "Chapter 16: Review Cards",
        "chapter_index":"Chapter 16",
        "root_name": "rc16.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/rc/reviewcard16`,
        "length": "7"
    },
    {
        "title": "Chapter 17: Review Cards",
        "chapter_index":"Chapter 17",
        "root_name": "rc17.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/rc/reviewcard17`,
        "length": "7"
    },
    {
        "title": "Chapter 18: Review Cards",
        "chapter_index":"Chapter 18",
        "root_name": "rc18.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/rc/reviewcard18`,
        "length": "4"
    },
    {
        "title": "Chapter 19: Review Cards",
        "chapter_index":"Chapter 19",
        "root_name": "rc19.pdf",
        "start_page": "1",
        "url":`${process.env.REACT_APP_DW_URL}/rc/reviewcard19`,
        "length": "5"
    }
];
